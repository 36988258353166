import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAccordion.figmaUrl.android} codeUrl={checklists.componentAccordion.codeUrl.android} checklists={checklists.componentAccordion.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Accordion is a component that consists of a series of titles, and when you click on a specific title, the detailed description will be expanded. This will ensure that the user can focus on only one opened item at a time.`}</p>
    <p>{`The following is the format for using Legion Accordion`}</p>
    <h3>{`Basic Accordion`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`var expanded by remember {
  mutableStateOf(false)
}

LgnAccordion(
    title = "Accordion Title",
    onClick = {
        expanded = !expanded
    },
    expanded = expanded
) {
  AccordionItemContent()
}
`}</code></pre>
    <h3>{`Accordion with Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`LegionAccordion(
...
icon = {
      Icon(imageVector = Icons.Default.AccountCircle, contentDescription = "")
    }
...  
){
  AccordionItemContent()
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <h3>{`Accordion with Divider`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`LegionAccordion(
...
divider = true //default false
...  
){
  AccordionItemContent()
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the text showing in accordion header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onClick`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`will be called when accordion is clicked`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`expanded`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Control accordion layout to animate when to display content.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`modifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the Modifier to be applied to this accordion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`colors`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color that will be used to resolve the colors used for this accordion.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`divider`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To display divider at accordion.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon to be displayed on the right of accordion.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`null`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`he content to be displayed on accordion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`Example`}</h2>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/accordion-android-1.gif",
            "width": 280,
            "height": 500,
            "align": "center",
            "alt": "accordion - android",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      